import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, My name is',
  name: 'Zach Bennett',
  subtitle: 'I am a robotics engineer, developer, and mechanical engineer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'about3.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'hpwordle_crop.jpg',
    title: 'Harry Potter Wordle',
    info: 'A spin off of the popular wordle game with a Harry Potter flare. 137,000 users since its inception, and staying strong with a 400-500 daily active users.',
    info2: 'Created with React, Typescipt, Tailwind, and hosted with Vercel',
    url: 'https://www.harrypotterwordle.com',
    // repo: 'https://github.com/zbennett/hpwordle', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'myrtle_crop.jpg',
    title: 'Myrtle',
    info: 'A Harry Potter character guessing game. Not as popular as as the wordle game but has had 15,000 users since its inception and has about 100 users per day.',
    info2: 'Created with React, Typescipt, Tailwind, and hosted with Vercel',
    url: 'https://myrtle.harrypotterwordle.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'dueldle.jpg',
    title: 'Dueldle',
    info: 'A head to head live wordle battle game. Both users log in and you can battle to see who can get the word faster and in less guesses. Once and for all, figure out which of your friends is best at Wordle. Average engagement time on the site is around 10 mintues.',
    info2: 'Created with React, Typescipt, Supabase, Tailwind, and hosted with Vercel',
    url: 'https://dueldle.app',
    // repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'bridge2.jpg',
    img2: 'bridge2.jpg',
    title: 'Bridge Analyzer',
    info: 'This is a website that will take a previously played game from Bridgebase.com and analyze the results. It takes in a .lin file which describes who had which cards and the order the cards were played in. This tool will replay the game, figure out who won each trick so that it can keep track of rubber scoring (which bridge base does not do) and create a summary for the night. This analysis is stored in a database so you can query results over time and see how the cards fell. It was using this tool that I realized that the East/West seats get better cards about 80% of the time.',
    info2: '',
    url: 'https://bridge.zachbennett.com',
    // repo: '', // if no repo, the button will not show up
  },
];

// PATENTS DATA
export const patentsData = [
  {
    id: nanoid(),
    img: 'preheat.jpg',
    title: 'Robotic Pre-Heat and Interpass Welding',
    info: 'Essentially a flame thrower on the end of a robot, this tool allowed you to heat up large weld joints before or in-between weld passes. By heating up a weld joint before welding, you would get a slower cooling rate of both weld metal and base metal which leads to a greater resistance to cracking.',
    info2:
      'The on-board infrared temperature gauge allowed for temperature checks in-between weld passes to make sure adequate temperature is held.',
    url: 'https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/9114473',
  },
  {
    id: nanoid(),
    img: 'collab.jpg',
    title: 'Robot with Smart Trajectory Recording',
    info: 'Collaborative robots opened up a world of humans working WITH robots, so we set out for a way to change how they are programmed as well. I created an interface where you would program a robot by physically grabbing it and moving it to where you wanted it to go. The software would record the motion and store it for play back later. It creates a whole welding program in just a few seconds, opening up the ability to do high mix, low volume product mix (a lot of variety) as well what skill level is needed to do the programming.',
    info2: '',
    url: 'https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/20230150131',
  },
  {
    id: nanoid(),
    img: 'icc.jpg',
    title: 'Individual Cup Control Gripper',
    info: 'With this parcel gripper you have control over which cups you want to turn on, and which to leave off. With most parcel grippers you have all the cups activated at the same time. The issue with this is when a package is smaller than the gripper, you will either have a bunch of flow going through the empty cups, weakening the suction on the cups you have engaged with the package or you are going to end up picking up multiple packages which is unacceptable in the industry. We developed a way to optimize the amount of cups on the package without the fear of double picks or drops due to loss of suction.',
    info2: '',
    // url: 'https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/20230150131',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: 'https://twitter.com/HPWordle',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/zachbennett/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/zbennett',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
